/* Variables */
:root {
  --main-color: #007BFF;
  --hover-color: #0056b3;
  --bg-light: #f7fafc;
  --bg-white: #fff;
  --bg-note: #f2f2f2;
  --border-color: #ccc;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --font-color: #333;
}

/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: var(--bg-light);
  margin: 0;
  padding: 0;
  color: var(--font-color);
}

/* Container */
.container {
  display: flex;
  max-width: 1200px;
  margin: 2em auto;
  height: 90vh;
  padding: 1em;
  gap: 1em;
  box-sizing: border-box;
  align-items: flex-start;
  overflow: visible;
}

/* Notes Section */
.notes-section {
  flex: 1;
  height: 100%;
  padding: 1em;
  background-color: var(--bg-white);
  border-radius: 0.5em;
  box-shadow: 0 4px 16px var(--shadow-color);
  overflow: auto;
  position: relative;
}

.notes-section textarea,
.notes-section .markdown-body {
  width: 100%;
  height: calc(100% - 60px);
  margin-bottom: 0.5em;
  resize: none;
  border: 1px solid var(--border-color);
  padding: 0.5em;
  border-radius: 0.3em;
}

/* Buttons */
button {
  padding: 0.5em 1em;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-color);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: var(--hover-color);
}

/* Chat Toggle */
.chat-toggle {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
  z-index: 10;
}

.chat-toggle input {
  display: none;
}

.chat-toggle label {
  padding: 0.5em 1em;
  background-color: var(--bg-note);
  border: 1px solid var(--border-color);
  border-radius: 0.3em;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Chat Section */
.chat-section {
  flex-basis: 35%;
  height: 100%;
  padding: 1em;
  background-color: var(--bg-white);
  border-radius: 0.5em;
  box-shadow: 0 4px 16px var(--shadow-color);
}

.hidden {
  display: none;
}

/* Messages */
.messages-list {
  height: 75%;
  overflow-y: auto;
  margin-bottom: 1em;
  border: 1px solid var(--border-color);
  border-radius: 0.3em;
  padding: 0.5em;
}

.message.user,
.message.ai {
  white-space: pre-line;
}

.message.user {
  color: blue;
}

.message.ai {
  color: green;
}

/* Message Input */
.message-input {
  display: flex;
  gap: 1em;
  align-items: flex-start;
}

.message-input textarea {
  flex-grow: 1;
  resize: none;
  border: 1px solid var(--border-color);
  padding: 0.5em;
  border-radius: 0.3em;
  max-height: 100px;
}

/* Context Menu */
.custom-context-menu {
  position: absolute;
  background-color: var(--bg-white);
  border: 1px solid var(--border-color);
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 200px;
  padding: 0.3em;
}

.custom-context-menu input {
  width: 100%;
  padding: 0.3em;
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  margin-top: 0.3em;
}

/* === Config Table Section === */

.config-section {
  flex: 1;
  padding: 1.5em;
  background-color: var(--bg-white);
  border-radius: 0.5em;
  box-shadow: 0 4px 16px var(--shadow-color);
  overflow-y: auto;  /* Changed from 'visible' for better scroll handling */
  position: relative;
}

.config-section h2 {
  font-size: 1.2em;
  margin-bottom: 1em;
}

.config-section table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
}

.config-section th, 
.config-section td {
  border: 1px solid var(--border-color);
  padding: 0.5em;
  text-align: left;
}

/* Textarea and Input Styles */

.config-section textarea,
.config-section input[type="text"] {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 0.5em;
  border: 1px solid var(--border-color);
  border-radius: 0.3em;
  box-sizing: border-box;
}

/* Button Styles */

.config-section button {
  cursor: pointer;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  background-color: var(--main-color);
  color: white;
  border: none;
  border-radius: 0.3em;
  transition: background-color 0.3s ease;
}

.config-section button:hover {
  background-color: var(--hover-color);
}
